
const userMenu = [
  {
    "label": "User management",
    "link": "/user/normal-user",
    "icon": "ri-user-line"
  },
  {
    label: "Employee management",
    link: "/user/employee",
    icon: "ri-user-2-line"
  },
  {
    "label": "CareGiver's CV",
    "link": "/user/cv",
    "icon": "ri-mail-star-line"
  },
  {
    "label": "Contact management",
    "link": "/user/contact",
    "icon": "ri-account-box-line"
  },
  {
    "label": "Level management",
    "link": "/user/level",
    "icon": "ri-account-box-line"
  }
];

const serviceMenu = [
  {
    "label": "Package management",
    "link": "/service/package",
    "icon": "ri-briefcase-4-line"
  },
  {
    "label": "CV template management",
    "link": "/service/cvtemplate",
    "icon": "ri-article-line"
  }
];

const calendarMenu = [
  {
    "label": "Calendar management",
    "link": "/calendar/list",
    "icon": "ri-calendar-check-line"
  }
]

const otherMenu = [
  {
    label: "System message",
    link: "/system-message",
    icon: "ri-message-3-line"
  }
]

// const applicationsMenu = [
//   {
//     "label": "Media Gallery",
//     "icon": "ri-gallery-line",
//     "submenu": [
//       {
//         "label": "Music Stream",
//         "link": "/apps/gallery-music"
//       },
//       {
//         "label": "Video Stream",
//         "link": "/apps/gallery-video"
//       }
//     ]
//   }
// ];


export { userMenu, serviceMenu, calendarMenu, otherMenu};
import React, { memo } from "react";


import LevelForm from "../components/LevelForm";

const AddLevel = (props) => {

  return (
    <LevelForm />
  );
};

export default memo(AddLevel);

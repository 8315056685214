import { useState } from "react";

export default function useUserProfile(){
    const getUserProfile = () => {
        const profile = localStorage.getItem('profile');
        return JSON.parse(profile)
    };
    const [profile, setProfile] = useState(getUserProfile());
    const saveUserProfile = profile => {
        console.log(profile)
        localStorage.setItem('profile', JSON.stringify(profile));
        setProfile(profile);
    };
    return {
        setProfile: saveUserProfile,
        profile
    }
}
import FormProvider from "@components/hook-form/form-provider";
import RHFTextField from "@components/hook-form/rhf-text-field";
import Notification from "@components/Notification";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@hooks/useBoolean";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { Form } from "react-bootstrap";
import { createLevel, getLevel, listLevelRequest, updateLevel } from "../../../../services/levelService";
import { useRouter } from "@hooks/useRouter";

const defaultValues = {
  name: "",
  description: "",
};

const bookingSchema = Yup.object().shape({
  name: Yup.string().required("Bắt buộc nhập tên"),
  description: Yup.string().required("Bắt buộc nhập Mô tả"),
});

const LevelForm = ({ id }) => {
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const isEdit = !!id;

  console.log('id', id);
  

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(bookingSchema),
  });

  const router = useRouter();

  const {
    reset,
    formState: { errors },
  } = methods;



  const showToast = useBoolean(false);

  const showMessageToast = useCallback(
    (msg, variant) => {
      showToast.onTrue();
      setToastContent(msg);
      setToastVariant(variant);
    },
    [showToast]
  );

  const getLevelData = useCallback(async () => {
    const res = await getLevel(id);
    if (res.isError) {
      showMessageToast("Failed to get level", "danger");
      return;
    }
    reset(res);
  }, [id]);

  useEffect(() => {
    if (id) {
      getLevelData();
    }
  }, [id]);

  const { handleSubmit } = methods;

  const submit = async (values) => {
    if (isEdit) {
      const res = await updateLevel({
        ...values,
        id,
      });
      if (res.isError) {
        showMessageToast("Failed to update", "danger");
        return;
      }
      showMessageToast("Update successfully", "success");
      setTimeout(() => {
        router.back();
      }, 500);
      return;
    }
    const res = await createLevel(values);
    if (res.isError) {
      showMessageToast("Failed to create", "danger");
      return;
    }
    showMessageToast("Create successfully", "success");
    setTimeout(() => {
      router.back();
    }, 500);
  };

  return (
    <div className="main main-docs">
      <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
        <Container>
          <label className="main-title-label">Level</label>
          <h2 className="main-title">
            {isEdit ? "Edit level" : "Add new level"}
          </h2>

          <h5 className="main-subtitle" id="section-1">
            1. Thông tin cơ bản
          </h5>

          <Row className="align-items-center mt-4">
            <Col>
              <Form.Label>Name:</Form.Label>
              <RHFTextField name="name" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Description:</Form.Label>
              <RHFTextField name="description" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </FormProvider>
      <Notification
        showToast={showToast.value}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={showToast.onFalse}
      />
    </div>
  );
};

export default memo(LevelForm);

import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, Row, Table } from "react-bootstrap";
import { DateTime } from "luxon";
import DatePicker from "react-date-picker";
import Notification from "@components/Notification";
import PaginationComp from "@components/PaginationComp";
import { listContactRequest } from "@services/contactService";
import Header from "@layouts/Header";
import { useRouter } from "@hooks/useRouter";
import { deleteContact } from "../../../services/contactService";

const GENDER = {
  0: "Nam",
  1: "Nữ",
};

export default function ContactManagement() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [fromDate, setFromDate] = useState(yesterday);
  const [toDate, setToDate] = useState(new Date());
  const [contactType, setContactType] = useState("all");
  const [contacts, setContacts] = useState([]);

  const router = useRouter();

  const onContactTypeChange = (event) => {
    if (event.target.checked) {
      setContactType(event.target.value);
    }
  };
  const listContact = async () => {
    let fDate = new Date(fromDate);
    fDate.setHours(0, 0, 0);
    let tDate = new Date(toDate);
    tDate.setHours(23, 59, 59);
    // let fd = (DateTime.fromJSDate(fDate)).toISODate();
    // let td = DateTime.fromJSDate(tDate).toISODate();
    const data = await listContactRequest(
      contactType,
      fDate,
      tDate,
      pageIndex,
      pageSize
    );
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    setContacts(data.items);
    setTotalItem(data.total);
  };
  useEffect(() => {
    listContact();
  }, [pageIndex]);
  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
  };
  const handlePaginationCallback = (size, index) => {
    setPageSize(size);
    setPageIndex(index);
  };

  const onAddContactClick = () => {
    // isShowAddCalendar.onTrue();
    router.push("/user/contact/add");
  };

  const onDelete = useCallback(async (id) => {
    const res = await deleteContact(id);
    if (res.isError) {
      displayNotification(res.msg, "danger");
      return;
    }
    displayNotification("Delete level successfully", "success");
    listContact();
    setPageIndex(0);
  }, [listContact]);

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={2}>From:</Col>
            <Col md={2}>
              <DatePicker value={fromDate} onChange={setFromDate} />
            </Col>
            <Col md={2}>To:</Col>
            <Col md={2}>
              <DatePicker value={toDate} onChange={setToDate} />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={2}>Contact type:</Col>
            <Col md={4}>
              {/* <FormCheck
                onChange={onContactTypeChange}
                type="radio"
                value="all"
                label="All"
                inline
                defaultChecked
                name="contact-type"
              />
              <FormCheck
                onChange={onContactTypeChange}
                type="radio"
                value="normuser"
                label="Normal contact"
                inline
                name="contact-type"
              />
              <FormCheck
                onChange={onContactTypeChange}
                type="radio"
                value="caregiver"
                label="Caregiver contact"
                inline
                name="contact-type"
              /> */}
            </Col>
            <Col md={2}>
              <Button variant="success" onClick={listContact}>
                Search
              </Button>
            </Col>
            <Col md={4} style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={onAddContactClick}>
                Add contact
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Phone number</th>
                    <th>Email</th>
                    <th>Level</th>
                    <th>Aging</th>
                    <th>Gender</th>
                    <th>Service</th>
                    <th>Address</th>
                    <th>Customer Number</th>
                    <th>Register Date</th>
                    <th>Health Status</th>

                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts && (
                    <>
                      {contacts.map((contact) => {
                        const contactLevels = contact.contactLevels;
                        const level = contactLevels.find(
                          (item) => item.isActive
                        )?.level;
                        return (
                          <>
                            <tr>
                              <td>{contact.fullName}</td>
                              <td>{contact.phoneNumber}</td>
                              <td>{contact.email}</td>
                              <td>{level.name}</td>
                              <td>{contact.aging}</td>
                              <td>
                                {GENDER[contact.gender] || "Chưa xác định"}
                              </td>
                              <td>{contact.service}</td>
                              <td>{contact.address}</td>
                              <td>{contact.customerNumber}</td>
                              <td>{contact.registerDate}</td>
                              <td>{contact.healthStatus}</td>

                              <td>
                                {DateTime.fromISO(
                                  contact.createdAt
                                ).toLocaleString()}
                              </td>
                              <td className="d-flex gap-1 justify-content-center">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  router.push(`/user/contact/edit/${contact.id}`);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  onDelete(contact.id);
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col md={4}>
              <PaginationComp
                total={totalItem}
                pageSize={pageSize}
                callback={handlePaginationCallback}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />
    </React.Fragment>
  );
}

import FormProvider from "@components/hook-form/form-provider";
import RHFSelect from "@components/hook-form/rhf-select";
import RHFTextField from "@components/hook-form/rhf-text-field";
import Notification from "@components/Notification";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@hooks/useBoolean";
import { getCities } from "@services/calendarService";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { listAllPackageRequest } from "@services/packageService";
import { map, orderBy } from "lodash";

import { useRouter } from "@hooks/useRouter";
import { Form, Nav } from "react-bootstrap";
import { createContact, getContact } from "../../../../services/contactService";
import { listLevelRequest } from "../../../../services/levelService";
import { getDirtyValues } from "../../../../utils/form";
import RHFDatePicker from "@components/hook-form/rhf-date-picker";
import useUserProfile from "../../../../components/useUserProfile";
import { splitAddress } from "@utils/format-string";

export const CITIES = ["Hồ Chí Minh", "Bà Rịa-Vũng Tàu", "Hà Nội"];

const defaultValues = {
  city: CITIES[0], // Hồ Chí Minh
  district: "",
  street: "",
  service: "",
  serviceType: "",
  note: "",
  fullName: "",
  phoneNumber: "",
  email: "",
  aging: 0,
  gender: "",
  calendarId: "",
  contactedBy: "",
  customerNumber: "",
  registerDate: "",
  healthStatus: "",
  packageItemId: "",
  reason: "",
  detailReason: "",
  customerSatisfied: true,
  customerSatisfiedDetail: "",
  customerSatisfiedRate: 0,
  isReBooked: false,
  contactFlow: "inbound",
  sourceFrom: "others",
  levelId: "",
  specialPriceRequest: "",
  specialRequest: "none",
  serviceStartDuration: "",
  personalPreference: "",
  quotationsNumner: 0,
  quotationsReason: "",
  potentialGroup: "high",
  customerFeedback: "",
};

const bookingSchema = Yup.object().shape({
  city: Yup.mixed().required("Bắt buộc nhập thành phố"),
  levelId: Yup.string().required("Bắt buộc nhập level"),
  district: Yup.string().required("Bắt buộc nhập quận/huyện"),
  street: Yup.string().required("Bắt buộc nhập số nhà/đường"),
  note: Yup.string(),
  fullName: Yup.string().required("Bắt buộc nhập họ tên"),
  phoneNumber: Yup.string().required("Bắt buộc nhập số điện thoại"),
});

const ContactForm = ({ id }) => {
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [cities, setCities] = useState([]);
  const [packages, setPackages] = useState([]);
  const [levels, setLevels] = useState([]);

  
  const isEdit = !!id;

  const { profile } = useUserProfile();

  const router = useRouter();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(bookingSchema),
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = methods;

  const values = watch();

  const service = watch("service");
  const serviceType = watch("serviceType");

  const getContactData = useCallback(async () => {
    const res = await getContact(id);
    if (res.isError) {
      showMessageToast("Failed to get level", "danger");
      return;
    }
    const {street,district, city } = splitAddress(res.address);
    reset({
      ...res,
      street,
      district,
      city
    });
  }, [id]);

  useEffect(() => {
    if (id) {
        getContactData();
    }
  }, [id]);

  const packageItems = useMemo(
    () => packages.find((item) => item.id === service)?.packageItems || [],
    [packages, service]
  );

  useEffect(() => {
    if (packages.length === 0) return;
    methods.setValue("service", packages[0]?.id || "");
  }, [methods, packageItems, packageItems.length, packages]);

  useEffect(() => {
    if (packageItems.length === 0) return;
    methods.setValue("serviceType", packageItems[0]?.id || "");
  }, [methods, packageItems, packageItems.length]);

  // const packageItems = packageItemsData.filter((item) => item.itemStatus === 'actived-ready');

  const citiesFiltered = orderBy(
    cities.filter((city) => CITIES.includes(city.cityName)),
    ["cityName"],
    ["asc"]
  );

  const districts = useMemo(() => {
    return (
      orderBy(
        cities.find((city) => city.cityName === values.city)
          ?.districtCategories,
        ["districtName"],
        ["asc"]
      ) || []
    );
  }, [cities, values.city]);

  const showToast = useBoolean(false);

  const showMessageToast = useCallback(
    (msg, variant) => {
      showToast.onTrue();
      setToastContent(msg);
      setToastVariant(variant);
    },
    [showToast]
  );

  useEffect(() => {
    getCities().then((cities) => {
      if (cities.isError) {
        showMessageToast("Failed to get cities", "danger");
        return;
      }
      setCities(cities?.items || []);
    });
    listAllPackageRequest().then((packages) => {
      if (packages.isError) {
        showMessageToast("Failed to get packages", "danger");
        return;
      }
      setPackages(packages.items);
    });
    listLevelRequest().then((level) => {
      if (level.isError) {
        showMessageToast("Failed to get contact", "danger");
        return;
      }
      setLevels(level);
      setValue("levelId", level[0]?.id || "", {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
  }, []);

  useEffect(() => {
    if (districts.length === 0) return;
    methods.setValue("district", districts[0]?.districtName || "");
    setValue("gender", 0, { shouldValidate: true, shouldDirty: true });
  }, [districts, methods, setValue]);

  const submit = async (values) => {
    const submitDataInfo = getDirtyValues(dirtyFields, values);

    const serviceData = packages.find((item) => item.id === values.service);
    const serviceTypeData = packageItems.find(
      (item) => item.id === values.serviceType
    );

    const contactData = {
      ...submitDataInfo,
      address: `${values.street}, ${values.district}, ${values.city}`,
      consultantBy: profile?.id,
      packageItemId: values.serviceType,
      service: `${serviceData?.packageName} - ${serviceTypeData.itemName}`,
    };
    const res = await createContact(contactData);
    if (res.isError) {
      showMessageToast("Failed to create", "danger");
      return;
    }
    showMessageToast("Create successfully", "success");
    setTimeout(() => {
      router.back();
    }, 500);
  };

  return (
    <div className="main main-docs">
      <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
        <Container>
          <label className="main-title-label">Contact</label>
          <h2 className="main-title">Add new contact</h2>

          <h5 className="main-subtitle" id="section-1">
            1. Thông tin cơ bản
          </h5>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Full Name:</Form.Label>
              <RHFTextField name="fullName" />
            </Col>
            <Col md={6}>
              <Form.Label>Level:</Form.Label>
              <RHFSelect name="levelId">
                {map(levels, (level) => (
                  <option key={level.id} value={level.id}>
                    {level.name} - {level.description}
                  </option>
                ))}
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Phone Number:</Form.Label>
              <RHFTextField name="phoneNumber" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Email:</Form.Label>
              <RHFTextField name="email" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Aging:</Form.Label>
              <RHFTextField name="aging" type="number" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Gender:</Form.Label>
              <RHFSelect name="gender">
                <option value="0">Nam</option>
                <option value="1">Nữ</option>
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>City:</Form.Label>
              <RHFSelect name="city">
                {map(citiesFiltered, (city) => (
                  <option key={city.id} value={city.cityName}>
                    {city.cityName}
                  </option>
                ))}
              </RHFSelect>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>District:</Form.Label>
              <RHFSelect name="district">
                {map(districts, (district) => (
                  <option key={district.id} value={district.districtName}>
                    {district.districtName}
                  </option>
                ))}
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Street:</Form.Label>
              <RHFTextField name="street" />
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-2">
            2. Thông tin nghề nghiệp
          </h5>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Dịch vụ:</Form.Label>
              <RHFSelect name="service">
                {map(packages, (item) => (
                  <option key={item.id} value={item.id}>
                    {item.packageName}
                  </option>
                ))}
              </RHFSelect>
            </Col>
          </Row>

          {service && (
            <Row className="align-items-center mt-4">
              <Col md={12}>
                <Form.Label>Loại dịch vụ:</Form.Label>
                <RHFSelect name="serviceType">
                  {map(packageItems, (item) => (
                    <option key={item.id} value={item.id}>
                      {item.itemName}
                    </option>
                  ))}
                </RHFSelect>
              </Col>
            </Row>
          )}

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Contacted By:</Form.Label>
              <RHFTextField name="contactedBy" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Customer Number:</Form.Label>
              <RHFTextField name="customerNumber" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <div className="mb-2">Register Date:</div>
              <RHFDatePicker name="registerDate" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Health Status:</Form.Label>
              <RHFTextField name="healthStatus" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-start mt-4">
            <Col md={6}>
              <Form.Label>Reason:</Form.Label>
              <RHFTextField name="reason" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Detail Reason:</Form.Label>
              <RHFTextField name="detailReason" as="textarea" />
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-3">
            3. Thông tin khảo sát
          </h5>

          <Row className="align-items-start mt-4">
            <Col md={6}>
              <Form.Label>Customer Satisfied:</Form.Label>
              <RHFSelect name="customerSatisfied">
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </RHFSelect>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Customer Satisfied Detail:</Form.Label>
              <RHFTextField name="customerSatisfiedDetail" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Contact Flow:</Form.Label>
              <RHFSelect name="contactFlow">
                <option value={"inbound"}>Inbound</option>
                <option value={"outbound"}>Outbound</option>
              </RHFSelect>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Source From:</Form.Label>
              <RHFSelect name="sourceFrom">
                <option value={"landing_page"}>Landing Page</option>
                <option value={"apps"}>Apps</option>
                <option value={"hotline"}>Hotline</option>
                <option value={"zalo_ads"}>Zalo Ads</option>
                <option value={"zalo_oa"}>Zalo OA</option>
                <option value={"tiktok"}>Tiktok</option>
                <option value={"office"}>Office</option>
                <option value={"others"}>Others</option>
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Special Price Request:</Form.Label>
              <RHFTextField name="specialPriceRequest" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Special Request:</Form.Label>
              <RHFSelect name="specialRequest">
                <option value={"none"}>None</option>
                <option value={"gender"}>Gender</option>
                <option value={"age"}>Age</option>
                <option value={"experience"}>Experience</option>
                <option value={"other"}>Other</option>
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Personal Preference:</Form.Label>
              <RHFTextField name="personalPreference" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Service Start Duration:</Form.Label>
              <RHFTextField name="serviceStartDuration" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Quotations Numner:</Form.Label>
              <RHFTextField name="quotationsNumner" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Quotations Reason:</Form.Label>
              <RHFTextField name="quotationsReason" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Potential Group:</Form.Label>
              <RHFSelect name="potentialGroup">
                <option value={"high"}>High</option>
                <option value={"low"}>Low</option>
              </RHFSelect>
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-4">
            4. Thông tin khác
          </h5>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Customer Feedback:</Form.Label>
              <RHFTextField name="customerFeedback" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Note:</Form.Label>
              <RHFTextField name="note" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </FormProvider>
      <Notification
        showToast={showToast.value}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={showToast.onFalse}
      />

      <Nav id="navDocs" className="nav-docs">
        <label>On This Page</label>
        <Nav.Link href="#section-1">1. Thông tin cơ bản</Nav.Link>
        <Nav.Link href="#section-2">2. Thông tin nghề nghiệp</Nav.Link>
        <Nav.Link href="#section-3">3. Thông tin khảo sát</Nav.Link>
        <Nav.Link href="#section-4">4. Thông tin khác</Nav.Link>
      </Nav>
    </div>
  );
};

export default memo(ContactForm);

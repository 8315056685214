import { sendGetRequest, sendPostRequest } from "./service"

export const listContactRequest = async(contactType, fromDate, toDate, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size,
        fromDate: fromDate,
        toDate: toDate
    }
    if(contactType !== "all"){
        params.contactType = contactType
    }
    return await sendGetRequest("/api/private/admin/contact", params);
}

export const createContact = async(contact) => {
    return await sendPostRequest("post", "/api/private/admin/contact", contact);
}


export const deleteContact = async(id) => {
    return await sendPostRequest("delete", `/api/private/admin/contact`, {id: id});
}

export const getContact = async(id) => {
    return await sendGetRequest(`/api/private/admin/contact/${id}`);
}

export const updateContact = async(contact) => {
    return await sendPostRequest("put", `/api/private/admin/contact/${contact.id}`, contact);
}
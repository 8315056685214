import { sendGetRequest, sendPostRequest } from "./service"

export const listLevelRequest = async() => {
    return await sendGetRequest("/api/private/levels");
}

export const updateLevel = async(level) => {
    return await sendPostRequest("put", `/api/private/levels/${level.id}`, level);
}

export const createLevel = async(level) => {
    return await sendPostRequest("post", "/api/private/levels", level);
}

export const deleteLevel = async(levelId) => {
    return await sendPostRequest("delete", `/api/private/levels`, {id: levelId});
}

export const getLevel = async(levelId) => {
    return await sendGetRequest(`/api/private/levels/${levelId}`);
}
import React from "react";

import UserManagement from "../pages/admin/user/UserManagement";
import CareGiverCvManagement from "../pages/admin/user/CareGiverCvManagement";
import PackageManagement from "../pages/admin/service/PackageManagement";
import CalendarManagement from "../pages/admin/calendar";
import ContactManagement from "../pages/admin/contact";
import UserDetail from "../pages/admin/user/NormalUserDetail/UserDetail";
import UserTotalBooking from "../pages/admin/user/NormalUserDetail/UserTotalBooking";
import UserBookingDetail from "../pages/admin/user/NormalUserDetail/UserBookingDetail";
import UserFamilyMember from "../pages/admin/user/NormalUserDetail/UserFamilyMember";
import UserMemberDetail from "../pages/admin/user/NormalUserDetail/UserMemberDetail";
import CareGiverTotalBooking from "../pages/admin/user/CareGiverDetail/CareGiverTotalBooking";
import CareGiverBookingDetail from "../pages/admin/user/CareGiverDetail/CareGiverBookingDetail";
import CaregiverServiceApplied from "../pages/admin/user/CareGiverDetail/CaregiverServiceApplied";
import CareGiverServiceAppliedDetail from "../pages/admin/user/CareGiverDetail/CareGiverServiceAppliedDetail";
import PackageDetail from "../pages/admin/service/PackageDetail";
import CvTemplateManagement from "../pages/admin/service/CvTemplateManagement";
import SubPackageDetail from "../pages/admin/service/SubPackageDetail";
import EmployeeManagement from "../pages/admin/user/EmployeeManagement";
import SystemMessage from "../pages/admin/system/SystemMessage";
import AddCalendar from "../pages/admin/calendar/sections/AddCalendar";
import AddContact from "../pages/admin/contact/sections/AddContact";
import LevelManagement from "../pages/admin/level";
import AddLevel from "../pages/admin/level/sections/AddLevel";
import EditLevel from "../pages/admin/level/sections/EditLevel";
import EditContact from "../pages/admin/contact/sections/EditContact";

const protectedRoutes = [
  { path: "user/normal-user", element: <UserManagement /> },
  { path: "user/normal-user/:userId", element: <UserDetail /> },
  { path: "user/detail/totalbooking", element: <UserTotalBooking /> },
  { path: "user/detail/totalbooking/detail", element: <UserBookingDetail /> },
  { path: "user/detail/familymember", element: <UserFamilyMember /> },
  { path: "user/detail/familymember/detail", element: <UserMemberDetail /> },
  { path: "user/detail/caregivertotalbooking", element: <CareGiverTotalBooking /> },
  { path: "user/detail/caregivertotalbooking/detail", element: <CareGiverBookingDetail /> },
  { path: "user/detail/caregiverserviceapplied", element: <CaregiverServiceApplied /> },
  { path: "user/detail/caregiverserviceapplied/detail", element: <CareGiverServiceAppliedDetail /> },
  { path: "user/employee", element: <EmployeeManagement />},

  
  { path: "service/package", element: <PackageManagement /> },
  { path: "service/package/:packageId", element: <PackageDetail /> },
  { path: "service/subpackage/:subpackageId", element: <SubPackageDetail /> },
  { path: "service/cvtemplate", element: <CvTemplateManagement /> },


  { path: "user/cv", element: <CareGiverCvManagement /> },
  { path: "calendar/list", element: <CalendarManagement /> },
  { path: "calendar/add", element: <AddCalendar /> },

  { path: "user/contact", element: <ContactManagement /> },
  { path: "user/contact/add", element: <AddContact /> },
  { path: "user/contact/edit/:id", element: <EditContact /> },

  { path: "user/level", element: <LevelManagement /> },
  { path: "user/level/add", element: <AddLevel /> },
  { path: "user/level/edit/:id", element: <EditLevel /> },

  { path: "/system-message", element: <SystemMessage />}
];

export default protectedRoutes;

import Notification from "@components/Notification";
import { useRouter } from "@hooks/useRouter";
import Header from "@layouts/Header";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { deleteLevel, listLevelRequest } from "../../../services/levelService";

export default function LevelManagement() {
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [levels, setLevels] = useState([]);

  const router = useRouter();

  const fetchLevel = async () => {
    const data = await listLevelRequest();
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    setLevels(data);
  };

  useEffect(() => {
    fetchLevel();
  }, []);

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };

  const closeNotification = () => {
    setShowToast(false);
  };

  const onAddContactClick = () => {
    // isShowAddCalendar.onTrue();
    router.push("/user/level/add");
  };

  const onDelete = useCallback(async (id) => {
    const res = await deleteLevel(id);
    if (res.isError) {
      displayNotification(res.msg, "danger");
      return;
    }
    displayNotification("Delete level successfully", "success");
    fetchLevel();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row style={{ marginTop: "20px" }}>
            <Col md={8}></Col>
            <Col md={4} style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={onAddContactClick}>
                Add Level
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {levels &&
                    levels.map((level) => {
                      return (
                        <>
                          <tr>
                            <td>{level.id}</td>
                            <td>{level.name}</td>
                            <td>{level.description}</td>

                            <td>
                              {DateTime.fromISO(
                                level.createdAt
                              ).toLocaleString()}
                            </td>

                            <td className="d-flex gap-1 justify-content-center">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  router.push(`/user/level/edit/${level.id}`);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  onDelete(level.id);
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />
    </React.Fragment>
  );
}

import React, { memo, useEffect } from "react";


import LevelForm from "../components/LevelForm";
import { useParams } from "../../../../hooks/useParams";

const EditLevel = (props) => {
  const params = useParams();

  const { id } = params;

  return (
    <LevelForm id={id} />
  );
};

export default memo(EditLevel);

import React, { memo } from "react";


import { useParams } from "../../../../hooks/useParams";
import ContactForm from "../components/ContactForm";

const EditContact = (props) => {
  const params = useParams();

  const { id } = params;

  return (
    <ContactForm id={id} />
  );
};

export default memo(EditContact);

export const splitAddress = (input) => {
  const lastIndex = input.lastIndexOf(', ');
  if (lastIndex === -1) {
    return [input, '', ''];
  }
  const secondLastIndex = input.lastIndexOf(', ', lastIndex - 1);
  if (secondLastIndex === -1) {
    return [input.substring(0, lastIndex), input.substring(lastIndex + 2), ''];
  }
  const beforeSecondLastComma = input.substring(0, secondLastIndex);
  const betweenCommas = input.substring(secondLastIndex + 2, lastIndex);
  const afterLastComma = input.substring(lastIndex + 2);
  return {
    street: beforeSecondLastComma, 
    district: betweenCommas, 
    city: afterLastComma
  };
};

import React, { memo } from "react";


import ContactForm from "../components/ContactForm";

const AddContact = (props) => {

  return (
    <ContactForm />
  );
};

export default memo(AddContact);
